'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Popup from '../../Widgets/Popup.react';
import DurationSelector from '../../Input/DurationSelector.react';

import { toHuman } from '../../../utils/Duration';

import './EditTimings.scss';

export default class EditTimings extends Component {

    static propTypes = {
        recipe: PropTypes.object,

        onChangeRecipe: PropTypes.func,
        showOvernightTime: PropTypes.bool,
        selectContainerClass: PropTypes.string,
    };

    static defaultProps = {
        showOvernightTime: false,
        selectContainerClass: "select-container",
    };

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            handsOnTime: props.recipe?.hands_on_time,
            handsOffTimer: props.recipe?.hands_on_timer,
        };
    }

    openTimingsEditorModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    onChangeHandsOnTime = (duration) => {
        const { recipe, onChangeRecipe } = this.props;

        recipe.hands_on_time = duration;
        recipe.hands_on_time_manual = true;

        recipe.total_time = (recipe.hands_on_time || 0) + (recipe.hands_off_timer || 0) + (recipe.overnight_time || 0);
        this.setState({handsOnTime: recipe.hands_on_time});

        onChangeRecipe(recipe, true);
    }

    onChangeHandsOffTimer = (duration) => {
        const { recipe, onChangeRecipe } = this.props;
        const { totalTime }  = this.state;

        recipe.hands_off_timer = duration;
        recipe.hands_off_timer_manual = true;

        this.setState({handsOffTimer: recipe.hands_off_timer});
        onChangeRecipe(recipe, true);
    }

    onChangeOvernightTime = (duration) => {
        const { recipe, onChangeRecipe } = this.props;

        recipe.overnight_time = duration;

        onChangeRecipe(recipe, true);
    }

    renderModal = () => {
        const { recipe, showOvernightTime } = this.props;
        const { isModalOpen } = this.state;

        if (!isModalOpen) {
            return;
        }

        return (
            <Modal isOpen={true}
                className="el-modal el-modal3 el-modal3-fixed"
                overlayClassName="el-modal-overlay"
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Edit recipe timings"
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal3-container edit-timings-modal-container-new">
                    <div className="el-modal-container el-modal3-body-container">
                        <div className="el-labeled-input hands-on-time">
                            <label>
                                Preparation time:

                                <Popup className="explanation" button={<i className="icon-help" />}>
                                    <p>Preparation time is the time you actively spend working on the recipe.</p>
                                    <p>This includes any chopping or mixing, or the cooking time if the dish requires frequent stirring while its cooking.</p>
                                </Popup>
                            </label>

                            <DurationSelector duration={recipe.hands_on_time} onChange={this.onChangeHandsOnTime} />
                        </div>
                        <div className="el-labeled-input hands-off-timer">
                            <label>
                                Cooking and/or wait time:

                                <Popup className="explanation" button={<i className="icon-help" />}>
                                    <p>Cooking or waiting time is any time the dish is in progress, but you can be doing something else.</p>
                                    <p>This includes unattended cooking or baking time.</p>
                                </Popup>
                            </label>

                            <DurationSelector duration={recipe.hands_off_timer} onChange={this.onChangeHandsOffTimer} />
                        </div>

                        {showOvernightTime ?
                            <div className="el-labeled-input overnight-time">
                                <label>
                                    Overnight time:

                                    <Popup className="explanation" button={<i className="icon-help" />}>
                                        <p>Overnight time includes anytime you need to let the dish sit overnight.</p>
                                        <p>This includes things like marinades, soaking beans or oats, and overnight chilling</p>
                                    </Popup>
                                </label>

                                <DurationSelector duration={recipe.overnight_time} onChange={this.onChangeOvernightTime} />
                            </div>
                        : null}
                    </div>

                    <footer>
                        <button className="el-modal-ok-btn" onClick={this.closeModal}>done</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    render = () => {
        const { recipe, selectContainerClass } = this.props;
        const { handsOnTime, handsOffTimer } = this.state;

        const duration = (handsOnTime ||  handsOffTimer) ? (handsOnTime || 0) + (handsOffTimer || 0) : null;

        return (
            <section className="edit-recipe-timings">
                <div className={selectContainerClass} onClick={this.openTimingsEditorModal}>
                    {duration ? <p className="value">{toHuman(duration, true)}</p> : null}
                    {!duration ? <p className="placeholder">select</p> : null}

                    <button className="chevron">
                        <i className="icon-down-arrow" />
                    </button>
                </div>

                {this.renderModal()}
            </section>
        );
    }
}
