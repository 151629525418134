'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { pluralize, singularize } from 'inflected';
import Markdown from 'react-markdown';

import UserStore from '../../stores/UserStore';

import SmartFraction from '../Widgets/SmartFraction.react';
import IngredientWarning from '../Widgets/IngredientWarning.react';

import { getBestUnitForFood, getContentForIngredient } from '../../utils/Content';
import { smartCeil, roundForHumans, humanizeGrams, humanizeMilliliters, isSingular, inflectUnitOfMeasure } from '../../utils/Math';

import './RecipeIngredients.scss';

export default class RecipeIngredients extends Component {

    static propTypes = {
        profile: PropTypes.object,
        details: PropTypes.object.isRequired,
        scaling: PropTypes.number,
        showRecipeTitle: PropTypes.bool,

        subdetails: PropTypes.object,
        subrecipes: PropTypes.object,
    };

    static contextTypes = {
        foods: PropTypes.object,
        recipes: PropTypes.object,
        isPro: PropTypes.bool,
    }

    static defaultProps = {
        profile: {units_mode: 'english', preferences: {avoidances: [], diets: []}, family: [], prescriptions: []},
        scaling: 1,
        showYield: false,
    }

    constructor(props) {
        super(props);

        const { scaling = 1 } = props;
        const user = UserStore.getUser();

        this.state = {
            mode: 'default',
            scaling: scaling || 1,
            user,
        };
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.scaling != this.state.scaling) {
            this.setState({scaling: newProps.scaling || 1});
        }
    }

    toggleMode = (newMode) => {
        this.setState({mode: newMode});
    }

    massageAmountAndUnit = (scaling, {grams, measurement = {}, ingredient}, food, profile) => {
        let { amount, unit_of_measure = '' } = measurement;

        const { units_mode = 'english' } = profile || {};
        unit_of_measure = (unit_of_measure || '').trim();

        const { tags = [] } = food || {};

        let scaledAmount = (amount * scaling),
            ingredientName = ingredient || '';

        // Imperial weights chain up
        if (['ounces', 'ounce', 'oz', 'oz.'].includes(unit_of_measure.toLowerCase()) && scaledAmount >= 16) {
            scaledAmount = scaledAmount / 16;
            unit_of_measure = 'pound';
        }

        // Imperial volumes
        if (tags.includes('Liquid') && ['teaspoon', 'teaspoons', 'tsp', 'tsp.'].includes(unit_of_measure.toLowerCase()) && scaledAmount >= 9) {
            scaledAmount = scaledAmount / 3;
            unit_of_measure = 'tablespoon';
        }

        if (tags.includes('Liquid') && ['tablespoon', 'tablespoons', 'tbsp', 'tbsp.'].includes(unit_of_measure.toLowerCase()) && scaledAmount >= 4) {
            scaledAmount = scaledAmount / 16;
            unit_of_measure = 'cup';
        }

        if (tags.includes('Liquid') && ['cup', 'cups'].includes(unit_of_measure.toLowerCase()) && scaledAmount >= 8) {
            scaledAmount = scaledAmount / 4;
            unit_of_measure = 'quart';
        }

        if (tags.includes('Liquid') && ['quart', 'quarts', 'qt', 'qt.'].includes(unit_of_measure.toLowerCase()) && scaledAmount >= 4) {
            scaledAmount = scaledAmount / 4;
            unit_of_measure = 'gallon';
        }

        // Massaging for metric users
        if (units_mode === 'metric') {
            // Switch pounds to grams
            if (['pound', 'pounds', 'lb', 'lb.', 'lbs', 'lbs.'].includes(unit_of_measure.toLowerCase())) {
                scaledAmount = roundForHumans(scaledAmount * 28.3495 * 16);
                unit_of_measure = 'gram';
            }

            // Switch ounces to grams
            if (['ounces', 'ounce', 'oz', 'oz.'].includes(unit_of_measure.toLowerCase())) {
                scaledAmount = roundForHumans(scaledAmount * 28.3495);
                unit_of_measure = 'gram';
            }

            // Switch cups to grams as well, since there's no good worldwide standard for "cup"
            // despite the fact that english speaking metric using countries continue to use non-standard
            // units of measure....*cough*cough* looking at you Aussies!
            // if (['cup', 'cups'].includes(unit_of_measure.toLowerCase())) {
            //     scaledAmount = scaling * grams;
            //     unit_of_measure = 'gram';
            // }

            // Switch quarts to milliliters
            if (['quart', 'quarts', 'qt', 'qt.'].includes(unit_of_measure.toLowerCase())) {
                scaledAmount = scaledAmount * .946353;
                unit_of_measure = 'liter';
            }

            if (['gallon', 'gallons'].includes(unit_of_measure.toLowerCase())) {
                scaledAmount = scaledAmount * 3.78541;
                unit_of_measure = 'liter';
            }
        }

        // Convert mL to liters if more than 750
        if (tags.includes('Liquid') && ['ml', 'ml.'].includes(unit_of_measure.toLowerCase()) && scaledAmount >= 750) {
            scaledAmount = scaledAmount / 1000;
            unit_of_measure = 'liter';
        }

        // Convert grams to kilograms if more than 750
        if (['grams', 'gram'].includes(unit_of_measure.toLowerCase()) && scaledAmount >= 750) {
            scaledAmount = scaledAmount / 1000;
            unit_of_measure = 'kilogram';
        }

        scaledAmount = units_mode === 'metric'
                     ? roundForHumans(scaledAmount)
                     : smartCeil(scaledAmount);

        if (isSingular(scaledAmount) != isSingular(amount)) {
            if (unit_of_measure && food) {
                unit_of_measure = inflectUnitOfMeasure(
                    scaledAmount,
                    getBestUnitForFood(scaledAmount, unit_of_measure, food) || {description: unit_of_measure}
                );
            } else if (unit_of_measure) {
                unit_of_measure = inflectUnitOfMeasure(scaledAmount, {description: unit_of_measure});
            } else if (food && food.plural) {
                ingredientName = isSingular(scaledAmount)
                               ? (food.pretty_name || food.name)
                               : food.plural;
            } else if (food && food.language === 'en') {
                // If there is no unit of measure to inflect, we inflect the last word of the ingredient name instead
                const nameSplit = ingredientName.split(' ');
                nameSplit[nameSplit.length - 1] = isSingular(scaledAmount)
                                                ? singularize(nameSplit[nameSplit.length - 1])
                                                : pluralize(nameSplit[nameSplit.length - 1]);
                ingredientName = nameSplit.join(' ');
            }
        }

        if (units_mode === 'english' && !isNaN(scaledAmount)) {
            scaledAmount = <SmartFraction value={scaledAmount} />
        }

        return {
            scaledAmount,
            unit_of_measure,
            ingredientName
        };
    }

    renderIngredient = (ingredient, scaling, key) => {
        const { profile } = this.props;
        const { mode, user } = this.state;
        const { foods = {}, recipes = {}, isPro } = this.context;

        let className = "ingredient";

        const { hide_nutrition = false } = (user && user.preferences) || {};
        const content = getContentForIngredient(ingredient, {...recipes, ...foods});
        const food = ingredient.food && ingredient.food.uuid && foods[ingredient.food.uuid];

        let subItems = [];
        let { scaledAmount, unit_of_measure, ingredientName } = this.massageAmountAndUnit(scaling, ingredient, food, profile);

        if (mode == 'default' && scaledAmount) {
            subItems.push(
                <span className="amt" key="fr">
                    {scaledAmount}
                </span>
            );

            if (unit_of_measure) {
                subItems.push(<span className="uom" key="uom">{unit_of_measure}</span>);
            }
        } else if (mode == 'grams' && ingredient.milliliters) {
            subItems.push(
                <span className="amt" key="g">
                    {humanizeMilliliters(scaling * ingredient.milliliters)}
                </span>
            );
        } else if (mode == 'grams' && ingredient.grams) {
            subItems.push(
                <span className="amt" key="g">
                    {humanizeGrams(scaling * ingredient.grams)}
                </span>
            );
        } else if (mode == 'ounces' && ingredient.milliliters) {
            // Warning - mass to weight conversion! This only works where the local
            // acceleration field is 9.8m/s^2 (e.g. most places on the surface of Earth)
            subItems.push(
                <span className="amt" key="oz">
                    {roundForHumans(scaling * ingredient.milliliters * 0.033814)} fluid oz
                </span>
            );
        } else if (mode == 'ounces' && ingredient.grams) {
            // Warning - mass to weight conversion! This only works where the local
            // acceleration field is 9.8m/s^2 (e.g. most places on the surface of Earth)
            subItems.push(
                <span className="amt" key="oz">
                    {roundForHumans(scaling * ingredient.grams * 0.035274)} oz
                </span>
            );
        }

        if (ingredient.food && ingredient.food.recipe) {
            let recipeUrl = '/recipes/'+ingredient.food.recipe;
            subItems.push(
                <span key="n">{ingredientName}</span>
            );
        } else {
            subItems.push(<span className="name" key="n">{ingredientName}</span>);
        }

        if (ingredient.prep_step) {
            subItems.push(<em className="prep" key="p">{ingredient.prep_step}</em>);
        }

        const {general_notes = '', medical_notes = ''} = foods[ingredient?.food?.uuid] || {};
        let markdownSource = '';

        if (!hide_nutrition) {
            if (general_notes) {
                markdownSource = general_notes;
                if(medical_notes) {
                    markdownSource += `\n${medical_notes}`;
                }
            } else if (medical_notes) {
                markdownSource = medical_notes;
            }
        } else if (general_notes) {
            markdownSource = general_notes;
        }

        if(markdownSource.length) {
            subItems.push(
                <Markdown key="markdown" escapeHtml={true} className="markdown notes" source={markdownSource} />
            );
        }

        const hideAvoidances = isPro && profile.uuid == user.uuid;

        // Is this a food we need to avoid?
        if (!hideAvoidances && food && profile && profile.preferences && profile.preferences.avoidances) {
            const { avoidances = [], exclude_foods = [] } = profile.preferences;

            let avoid = avoidances.filter(tag => (food.ingredient_tags || []).includes(tag));

            if (avoid.length > 0 || exclude_foods.includes(food.uuid)) {
                className += ' avoid';

                subItems.push(<IngredientWarning key={5} />);
            }
        }


        return (
            <li key={key} className={className} data-mode={mode}>
                {subItems}
                {content?.brand_name ? <span className="brand">({content?.brand_name})</span> : null}
            </li>
        );
    }

    renderIngredientGroup = (group, scaling, i) => {
        return (
            <ul key={i}>
                {group.title ? <li><h5>{group.title}</h5></li> : <span />}
                {group.items.map((item, i) => this.renderIngredient(item, scaling, i))}
            </ul>
        );
    }

    renderSubrecipe = (subrecipe, i) => {
        const { subdetails } = this.props;
        const subdetail = subdetails && subdetails[subrecipe.details];

        if (!subdetail) {
            return;
        }

        return (
            <div className="subrecipe" key={i}>
                <h6 className="recipe-title">{subrecipe.title}</h6>

                <ul className="ingredients-list">
                    {subdetail.ingredients.map((group, i) => this.renderIngredientGroup(group, 1, i))}
                </ul>
            </div>
        );
    }

    render = () => {
        const { recipe, details, showYield, showRecipeTitle } = this.props;
        const { mode, scaling } = this.state;

        const recipeYield = recipe.servings * scaling;

        return (
            <div className="ingredients-container el-fonts">
                <div className="scaling-button-toggle pill-tabs"><section className="tabs hide-on-print">
                    <div data-status={mode === 'default' ? true : false}><button onClick={()=>this.toggleMode('default')}>Cups</button></div>
                    <div data-status={mode === 'grams' ? true : false}><button onClick={()=>this.toggleMode('grams')}>Grams</button></div>
                    <div data-status={mode === 'ounces' ? true : false}><button onClick={()=>this.toggleMode('ounces')}>Ounces</button></div>
                </section></div>

                {showRecipeTitle ?
                    <h6 className="recipe-title">{recipe.title}</h6>
                : null}

                {scaling != 1 && !showYield ?
                    <p className="t3 is-scaled">Ingredients and groceries scaled from original {recipe.servings} {recipe.serving == 1 ? 'serving' : 'servings'}</p>
                : null }

                {scaling != 1 && showYield ?
                    <p className="t3 yield">
                        Make {scaling} batches for {recipeYield} {recipeYield == 1 ? 'serving' : 'servings'}
                    </p>
                : null}

                {scaling == 1 && showYield ?
                    <p className="t3 yield">
                        Makes {recipeYield} {recipeYield == 1 ? 'serving' : 'servings'}
                    </p>
                : null}

                <ul className="ingredients-list">
                    {details && details.ingredients ? details.ingredients.map((group, i) => this.renderIngredientGroup(group, scaling, i)) : null}
                </ul>

                {(recipe.subrecipes || []).map(this.renderSubrecipe)}
            </div>
        );
    }
}
