'use strict'

import { Component } from "react";
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Alert from '../../Widgets/Alert/Alert.react';

import AuthStore from "../../../stores/AuthStore";
import { getConfig } from "../../../utils/Env";
import Analytics from "../../../utils/Analytics";
import { flattenIngredients } from "../../../utils/Recipe";
import { getRecipeEventProperties } from "../../../utils/Content";

import './QuickAddIngredients.scss';

export default class QuickAddIngredientsModal extends Component {
    static propTypes = {
        onChangeDetails: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            input: "",
            showModal: false,
            loading: false
        };
    }

    openModal = () => {
        this.setState({showModal: true});
    }

    closeModal = () => {
        this.setState({showModal: false, input: '', error: null, loading: false, delay: false});
    }

    onChange = (event) => {
        this.setState({input: event.target.value});
    }

    importRecipe = async () => {
        let { input } = this.state;
        const { groupIndex, details, onChangeDetails } = this.props;

        this.setState({ loading: true, error: false, delay: false });

        let timeoutId = false;

        input = input.trim();

        if (!input) {
            this.setState({error: "Please describe your meal by typing it into the text field above."});
            return;
        }

        const payload = {
            text: input
        }

        try {
            const response = await AuthStore.fetch(getConfig('recipe_api') + '/import-recipe', {
                method: "POST",
                headers: { "Content-Type": "application/json; schema=import/recipe/text/1"},
                body: JSON.stringify(payload),
            });

            if (!response?.links.self.href) {
                throw Error("Unable to import recipe: " + (response.message || 'unknown error'));
            }

            Analytics.startQuickAddIngredients({
                ...payload,
                'Job ID': response.uuid,
            });

            timeoutId = setTimeout(() => this.setState({ delay: true }), 30000);
            let jobUrl = getConfig("recipe_api") + response?.links.self.href;
            let job = await AuthStore.fetch(jobUrl);
            let governor = 500;

            while (job?.status !== "completed" && job?.status !== "failed" && governor-- > 0) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                job = await AuthStore.fetch(jobUrl);

                if (!this.state.showModal) {
                    // user closed the modal, abort and ignore.
                    Analytics.cancelQuickAddIngredients({
                        'Job ID': job.uuid,
                    });
                    clearTimeout(timeoutId);
                    return;
                }
            }

            if (job?.status !== "completed") {
                Analytics.quickAddIngredientsError({
                    error: job.output?.error,
                    'Job ID': job.uuid,
                });
                this.setState({ loading: false, error: job?.output?.error || 'unknown error', delay: false });
                return;
            }

            const { recipe, details: recipeDetails } = job?.output;

            const ingredients = flattenIngredients({recipeDetails});

            const newDetails = {...details};

            newDetails.ingredients[groupIndex].items = [...newDetails.ingredients[groupIndex].items, ...ingredients];

            onChangeDetails(newDetails, true);

            Analytics.quickAddIngredientsSuccess({
                ...getRecipeEventProperties(recipe, details),
                'Job ID': job.uuid,
            });

            this.setState({ showModal: false, loading: false, error: false, delay: false, input: '' });
        } catch (exp) {

            let error = (exp?.message || exp?.error || exp || 'unknown error');

            Analytics.quickAddIngredientsError({error});

            this.setState({ loading: false, error, delay: false });
        }

        if (timeoutId !== false) {
            clearTimeout(timeoutId);
        }
    }

    renderLoader = () => {
        const { loading } = this.state;

        if(!loading) {
            return;
        }

        return (
            <div className="ingredients-loading">
                <i className="icon-spinner2"/>
            </div>
        );
    }

    renderModal = () => {
        const { showModal, input, error, loading, delay } = this.state;

        if (!showModal) {
            return;
        }

        return (
            <Modal isOpen={true}
            onRequestClose={() => false}
            closeModal={() => false}
            contentLabel="Quick Add Ingredients"
            className={`el-modal el-modal3 el-modal3-centered`}
            overlayClassName={`el-modal-overlay`}
            closeTimeoutMS={250}>
                <div className="el-modal-container el-modal3-container">
                    {loading ?
                        <div className="el-modal-loading el-fonts ingredients-loader" data-testid="ingredients-loader">
                            <h4>Please wait</h4>
                            <i className="icon-spinner2" />
                            <span className="loading-text">Analyzing ingredients</span>
                        </div>
                    : <>
                        <header>
                            <h2>Quick Add Ingredients</h2>
                            <button className="el-modal-close-x" onClick={this.closeModal}>
                                <i className="icon-close-x" />
                                <span className="assistive-text">Close Modal</span>
                            </button>
                        </header>
                        <div className="el-modal-body-container el-modal3-body-container el-form el-fonts">
                            <section className="el-labeled-input">
                                <textarea className="quick-add-ingredients-textarea" placeholder="Simply paste or type your ingredients list here, and we'll instantly match them to foods in our database and add them to your recipe." value={input} onChange={this.onChange}/>
                            </section>
                            {error && <Alert type="error">{error}</Alert>}
                        </div>
                    </> }

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.closeModal}>Cancel</button>
                        <button disabled={loading} className="el-modal-ok-btn" onClick={this.importRecipe}>Add Ingredients</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    render() {

        return (
            <>
                <button className="el-medium-btn el-link-no-underline-raspberry-btn" onClick={this.openModal}>
                    Quick Add Ingredients
                </button>
                {this.renderModal()}
            </>
        );
    }
}
