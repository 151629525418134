'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import RecipeEditor from './Editor.react';

import { getConfig } from '../../utils/Env';
import Analytics from '../../utils/Analytics';
import { getRecipeEventProperties } from '../../utils/Content';
import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';
import platform from 'platform';

import './AddRecipe.scss';

export default class AddRecipe extends Component {

    static propTypes = {
        profile: PropTypes.object,
        closeModal: PropTypes.func,
    };


    static contextTypes = {
        onSelectRecipe: PropTypes.func,
        addSwapContext: PropTypes.object,
    };


    constructor(props, context) {
        super(props, context);

        this.state = {
            mode: 'import',
            working: false,
            recipeUrl: null,
            recipe: null,
            details: null,
            error: null,
            importFailure: false,
            importSuccess: false,
        };
    }

    setCreateMode = () => {
        this.setState({mode: 'create'});
    }


    setImportMode = () => {
        this.setState({mode: 'import'});
    }

    onChangeRecipeUrl = (ev) => {
        this.setState({recipeUrl: ev.target.value});
    }


    onSaveRecipe = (recipe) => {
        const { closeModal, onSaveRecipe } = this.props;

        if (onSaveRecipe) {
            onSaveRecipe(recipe);
        }
        
        this.setState({recipe: recipe});
        closeModal && closeModal();
    }


    importRecipe = async (body, schema) => {
        this.setState({ working: 'importing', error: false, delay: false, importSuccess: false, importFailure: false });

        let timeoutId = false;

        try {
            const response = await AuthStore.fetch(getConfig('recipe_api') + '/import-recipe', {
                method: "POST",
                headers: { "Content-Type": "application/json; schema=" + schema },
                body: JSON.stringify(body),
            });

            if (!response?.links.self.href) {
                throw Error("Unable to import recipe: " + (response.message || 'unknown error'));
            }

            Analytics.startImportRecipe({
                ...body,
                'Job ID': response.uuid,
            });

            timeoutId = setTimeout(() => this.setState({ delay: true }), 30000);
            let jobUrl = getConfig("recipe_api") + response?.links.self.href;
            let job = await AuthStore.fetch(jobUrl);
            let governor = 500;

            while (job?.status !== "completed" && job?.status !== "failed" && governor-- > 0) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                job = await AuthStore.fetch(jobUrl);
            }

            if (job?.status !== "completed") {
                Analytics.importRecipeError({
                    error: job.output?.error,
                });
                this.setState({ working: false, error: job?.output?.error || 'unknown error', delay: false, importFailure: true, mode: 'create' });
                return;
            }

            this.setState({ working: false, error: false, delay: false });

            const { recipe, details } = job?.output;

            recipe.owner = UserStore.getUser().uuid;


            this.setState({ recipe: recipe, details: details, importSuccess: true, mode: 'create'  });

            Analytics.importRecipeSuccess({
                ...getRecipeEventProperties(recipe, details),
            });
        
        } catch (exp) {
            let error = (exp?.message || exp?.error || exp || 'unknown error');

            Analytics.importRecipeError({error});

            this.setState({ working: false, error, delay: false });
        }

        if (timeoutId !== false) {
            clearTimeout(timeoutId);
        }
    }


    renderRecipeEditor = () => {
        const { profile, closeModal } = this.props;
        const { mode, error, importSuccess, importFailure, recipe, details } = this.state;


        if (mode !== "create" && !importSuccess && !importFailure) {
            return;
        }

        return (
            <RecipeEditor
                profile={profile}
                closeModal={closeModal}
                onSaveRecipe={this.onSaveRecipe}
                importSuccess={importSuccess}
                importFailure={importFailure}
                recipe={recipe}
                details={details}
                savedOwnRecipeContext="Add/Swap" />
        )
    }

    render() {
        const { mode, working, recipeUrl, recipe } = this.state;
        const { profile } = this.props;

        return (
            <div className="add-recipe-container" data-has-toggles={!recipe ? true : false}>
                {!recipe ?
                    <>
                        <div className="toggle-btn-group">
                            <button data-active={mode === 'import'} onClick={this.setImportMode}>Import Recipe</button>
                            <button data-active={mode === 'create'} onClick={this.setCreateMode}>Add Manually</button>
                        </div>

                        {mode == "import" || working ?
                            <div className="add-recipe-form">
                                {mode == "import" ?
                                    <div className="el-labeled-input">
                                        <label>Automatically Import a Recipe</label>
                                        {!working ? <input type="text" placeholder="Paste the recipe URL here" onChange={this.onChangeRecipeUrl} /> : null}
                                    </div>
                                : null}
                                {working ?
                                <div className="loading-container">
                                    <i className="icon-spinner2" />
                                    <span className="loading-text">Please wait while we import your recipe</span>
                                </div>
                                : null}

                                {!working && mode == "import" ?
                                    <footer>
                                        <button class="el-medium-btn el-raspberry-btn" onClick={() => this.importRecipe({url: recipeUrl}, 'import/recipe/url/1')}>
                                            import
                                        </button>
                                    </footer>
                                : null}
                            </div>
                        : null}
                    </>
                : null}
                {this.renderRecipeEditor()}
            </div>
        );
    }
}
