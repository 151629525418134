'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './NoResults.scss';

export default class NoResults extends Component {

    static contextTypes = {
        showCreateCustom: PropTypes.func,
        createCustomButtonCopy: PropTypes.node,
    };

    static propTypes = {
        noResultsCopy: PropTypes.node,
        hideCreateCustom: PropTypes.bool,
        overrideCreateCustomButtonCopy: PropTypes.node
    };

    static defaultProps = {
        noResultsCopy: 'No results found',
    };

    render() {
        const { params, noResultsCopy, hideCreateCustom, overrideCreateCustomButtonCopy } = this.props;
        const { showCreateCustom, createCustomButtonCopy } = this.context;

        return (
            <div className="global-search-no-results">
                <h2>{noResultsCopy}</h2>
                <i className="icon-silverware1"/>
                {showCreateCustom && !hideCreateCustom ?
                    <footer>
                        <button className="create-custom-meal-btn" onClick={() => showCreateCustom({defaultCustomTitle: params.terms})}>
                            {overrideCreateCustomButtonCopy || createCustomButtonCopy || 'create custom food'}
                        </button>
                    </footer>
                : null}
            </div>
        );
    }
}
