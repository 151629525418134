'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FoodUnitsSelector from './FoodUnitsSelectorModal.react';

import { getNutrientsForMeals } from '../../utils/Nutrition';
import Analytics from '../../utils/Analytics';

import './MealRow.scss';

export default class MealRow extends Component {
    static contextTypes = {
        changeDefaultMealType: PropTypes.func,
    }

    static propTypes = {
        user: PropTypes.object,
        recipes: PropTypes.object,
        brands: PropTypes.object,
        foods: PropTypes.object,
        onRemoveMeals: PropTypes.func,
        showMealDetails: PropTypes.func,
    };

    unpackComponents = () => {
        const { user, meal, recipes, brands, foods } = this.props;
        let title = '', subtitle = '', recipe  =  null, brand = null, food = null;
        const portion = meal.logged_portion || user.portion;

        const nutrients = getNutrientsForMeals([meal], {...recipes, ...foods}, user.portion);
        let calories = nutrients[208] || 0;

        if ((meal.meal_type === 'fresh' || meal.meal_type === 'leftover') && recipes[meal.recipe_uuid]) {
            recipe = recipes[meal.recipe_uuid];

            title = meal.meal_type === 'leftover' ? <span><em>Leftover:</em> {recipe.title}</span> : recipe.title;
        }

        if (meal.meal_type === 'food' && foods[meal.food_uuid]) {
            food = foods[meal.food_uuid];

            title = food.pretty_name || food.name;
            subtitle = food.brand_name;
        }

        return { title, subtitle, calories, recipe, food };
    }

    shouldLogPortions = (meal) => {
        const { mode } = this.props;

        if (mode === 'future') {
            return false;
        }

        if  (meal.deleted) {
            return false;
        }

        if (meal.logged_portion) {
            return false;
        }

        return true;
    }

    undoDelete = () => {
        const { meal, onModifyMeal } = this.props;

        if (!onModifyMeal || !meal) {
            return;
        }

        delete meal.deleted;

        onModifyMeal(meal);
    }

    gotoNextMeal = (mealType) => {
        const { changeDefaultMealType } = this.context;

        // We're not going to change dates, we'll only change meals
        const properOrder = ['Breakfast', 'Snack', 'Lunch', 'Dinner'];
        const i = properOrder.indexOf(mealType);

        if (i !== -1 && properOrder[i + 1]) {
            setTimeout(() => {
                changeDefaultMealType(properOrder[i + 1]);
            }, 2500)
        }
    }

    saveLoggedPortion = () => {
        const { user, mode, isPrimary, meal, food, onModifyMeal } = this.props;

        meal.logged_portion = 1;

        onModifyMeal(meal);

        // Do we need to move to the next card?
        if (mode === 'current' && isPrimary) {
            this.gotoNextMeal(meal.meal);
        }

        Analytics.userDidEat({
            'Date': meal.date,
            'Meal Type': meal.meal,
            'Meal UUID' : meal.uuid
        });
    }

    onRemoveMeals = () => {
        const { meal, onRemoveMeals } = this.props;

        onRemoveMeals([meal]);

        Analytics.userDidNotEat({
            'Date': meal.date,
            'Meal Type': meal.meal,
            'Meal UUID': meal.uuid
        });
    }

    removeLoggedPortion = () => {
        this.onChangeLoggedPortion(0);
    }

    renderPortionLogger = () => {
        const { user, meal, onRemoveMeals } = this.props;

        return (
            <section className="log-portions">
                <p>Did you eat this?</p>
                <button className="yes-btn" onClick={this.saveLoggedPortion}>Yes</button>
                <button className="no-btn" onClick={this.onRemoveMeals}>No</button>
            </section>
        );
    }

    renderUndeleter = () => {
        const { meal, onRemoveMeals } = this.props;

        return (
            <section className="undeleter">
                <button className="no-btn" onClick={this.undoDelete}>undo delete</button>
            </section>
        );
    }

    onClickMealInfo = () => {
        const { meal, showMealDetails, dishIndex } = this.props;

        showMealDetails([meal], dishIndex);
    }

    render() {
        const { user, mode, meal, showMealsNutrition, onRemoveMeals } = this.props;
        const { title, subtitle, calories, recipe, food } = this.unpackComponents();

        const logPortions = this.shouldLogPortions(meal);
        const { hide_nutrition = false } = (user && user.preferences) || {};

        return (
            <li className="daily-log-meal" data-log-portions={logPortions} data-deleted={meal.deleted} data-mode={mode}>
                <section className="row-text">
                    <span onClick={this.onClickMealInfo}>
                        {title}
                        {subtitle ? <span className='subtitle'>{subtitle}</span> : null}
                        {!hide_nutrition && calories && logPortions ? <p className="calories">{`${Math.round(calories)} calories`}</p> : null}
                        {title && mode == 'future' ? <span className="planned"> - PLANNED</span> : null}
                    </span>
                    {!meal.deleted && meal.logged_amount && (recipe || food) ?
                        <FoodUnitsSelector food={recipe || food} meal={meal} />
                    : null}
                </section>

                {logPortions ? this.renderPortionLogger() : null}
                {meal.deleted ? this.renderUndeleter() : null}

                {(!logPortions && !meal.deleted) ?
                    <footer>
                        {!hide_nutrition && calories ? <p className="calories">{Math.round(calories)}</p> : null}
                        <button className="remove-log-btn" onClick={this.onRemoveMeals}>
                            <i className="icon-close-x" />
                        </button>
                        <button className="show-nutrition-btn" onClick={showMealsNutrition}>
                            <i className="icon-chevron-right" />
                        </button>
                    </footer>
                : null}
            </li>
        );
    }
}
